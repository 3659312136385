import moment from "moment";
//
import ACCOUNTING from "@configs/ability/Accounting";
import SALE from "@configs/ability/Sale";

const PAGINATION_TYPES = {
  product: "PRODUCT",
  supplier: "SUPPLIER",
  market_organogram: "MARKET_ORGANOGRAM",
  supplier_credit: "SUPPLIER_CREDIT",
  customer: "CUSTOMER",
  user: "USER",
  user_app_list: "USER_APP_LIST",
  customer_branch: "CUSTOMER_BRANCH",
  customer_group: "CUSTOMER_GROUP",
  purchase: "PURCHASE",
  proforma: "PROFORMA",
  unit: "UNIT",
  requisition: "REQUISITION",
  account: "ACCOUNT",
  financial_year: "FINANCIAL_YEAR",
  transaction: "TRANSACTION",
  customer_advance: "CUSTOMER_ADVANCE",
  supplier_advance: "SUPPLIER_ADVANCE",
  supplier_advance_adjustment: "SUPPLIER_ADVANCE_ADJUSTMENT",
  ledger: "LEDGER",
  cost_center: "COST_CENTER",
  profit_center: "PROFIT_CENTER",
  bank: "BANK",
  bank_account: "BANK_ACCOUNT",
  test_parameter: "TEST_PARAMETER",
  purchase_receive: "PURCHASE_RECEIVE",
  purchase_receive_qc: "PURCHASE_RECEIVE_QC",
  purchase_receive_invoice: "PURCHASE_RECEIVE_INVOICE",
  purchase_receive_invoice_return: "PURCHASE_RECEIVE_INVOICE_RETURN",
  fixed_asset: "FIXED_ASSET",
  fixed_asset_unlisted: "FIXED_ASSET_UNLISTED",
  fixed_asset_category: "FIXED_ASSET_CATEGORY",
  fixed_asset_queue: "FIXED_ASSET_QUEUE",
  bom: "bom",
  production_planning: "PRODUCTION_PLANNING",
  reprocess: "REPROCESS",
  production_planning_receive: "PRODUCTION_PLANNING_RECEIVE",
  collection: "COLLECTION",
  collection_discount: "COLLECTION_DISCOUNT",
  deposit: "DEPOSIT",
  order: "ORDER",
  order_wish_list: "ORDER_WISH_LIST",
  route: "ROUTE",
  route_plan: "ROUTE_PLAN",
  own_plan: "OWN_PLAN",
  sale_discount: "SALE_DISCOUNT",
  sale_return: "SALE_RETURN",
  van: "VAN",
  delivery_assistant: "DELIVERY_ASSISTANT",
  price: "PRICE",
  sale: "SALE",
  production_planning_receive_qc: "PRODUCTION_PLANNING_RECEIVE_QC",
  challan: "CHALLAN",
  sale_foc: "SALE_FOC",
  sales_target: "SALES_TARGET",
  sale_collection_discount: "SALE_COLLECTION_DISCOUNT",
  invoice: "invoice",
  inventory_transaction: "INVENTORY_TRANSACTION",
  accounting_purchase_payment: "ACCOUNTING_PURCHASE_PAYMENT",
  stock_transfer: "STOCK_TRANSFER",
  ic_stock_transfer: "IC_STOCK_TRANSFER",
  icst_receive: "ICST_RECEIVE",
  stock_transfer_receive: "STOCK_TRANSFER_RECEIVE",
  inventory_loss_gain: "INVENTORY_LOSS_GAIN",
  promotional_item_issue: "PROMOTIONAL_ITEM_ISSUE",
  farm_fg_receive: "FARM_FG_RECEIVE",
  sample_invoice: "SAMPLE_INVOICE",
  expense_budget: "EXPENSE_BUDGET",
  lc_information: "LC_INFORMATION",
  amendment_information: "AMENDMENT_INFORMATION",
  pp_lc_cost_head: "PP_LC_COST_HEAD",
  pp_lc_cost_type: "PP_LC_COST_TYPE",
  pplccita: "PPLCCITA",
  pplccicfa: "PPLCCICFA",
  pplcci: "PPLCCI",
  pplccitd: "PPLCCITD",
  pplccit: "PPLCCIT",
  pplccifc: "PPLCCIFC",
  pplccioc: "PPLCCIOC",
  pplccicfp: "PPLCCICFP",
  pplccicfac: "PPLCCICFAC",
};

const PATCH_PAGINATION_TYPES = [
  PAGINATION_TYPES.requisition,
  PAGINATION_TYPES.financial_year,
  PAGINATION_TYPES.transaction,
  PAGINATION_TYPES.customer_advance,
  PAGINATION_TYPES.supplier_advance,
  PAGINATION_TYPES.supplier_advance_adjustment,
  PAGINATION_TYPES.production_planning,
  PAGINATION_TYPES.fixed_asset,
  PAGINATION_TYPES.order,
  PAGINATION_TYPES.collection,
  PAGINATION_TYPES.route_plan,
  PAGINATION_TYPES.purchase_receive_invoice_return,
  PAGINATION_TYPES.stock_transfer,
  PAGINATION_TYPES.ic_stock_transfer,
  PAGINATION_TYPES.icst_receive,
  PAGINATION_TYPES.inventory_loss_gain,
  PAGINATION_TYPES.promotional_item_issue,
  PAGINATION_TYPES.farm_fg_receive,
  PAGINATION_TYPES.deposit,
  PAGINATION_TYPES.sample_invoice,
  PAGINATION_TYPES.accounting_purchase_payment,
  PAGINATION_TYPES.sale_return,
  PAGINATION_TYPES.expense_budget,
  PAGINATION_TYPES.sales_target,
  PAGINATION_TYPES.proforma,
  PAGINATION_TYPES.lc_information,
  PAGINATION_TYPES.amendment_information,
  PAGINATION_TYPES.pp_lc_insurance,
  PAGINATION_TYPES.pplcci,
  PAGINATION_TYPES.pplccitd,
  PAGINATION_TYPES.pplccit,
  PAGINATION_TYPES.pplccifc,
  PAGINATION_TYPES.pplccioc,
  PAGINATION_TYPES.pplccicfp,
  PAGINATION_TYPES.pplccicfac,
];

const STORAGE_KEY = {
  USER: "userData",
  COMPANY: "selectedCompany",
};
const CONST_DATA_KEY = {
  FIREBASE: "FIREBASE",
};
const FIREBASE_URL_PREFIX = "transaction_status/CoreBundle\\Entity\\";
const FIREBASE_KEY = {
  PURCHASE_RECEIVE: `${FIREBASE_URL_PREFIX}Purchase\\Receive/`,
  PRODUCTION_PLANNING: `${FIREBASE_URL_PREFIX}Manufacture\\ProductionPlanning/`,
  INVENTORY_STOCK_ISSUE: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\Issue/`,
  INVENTORY_STOCK_TRANSFER: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\Transfer/`,
  IICST: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\Transfer\\InterCompany/`,
  IICSTR: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\Transfer\\InterCompany\\Receive/`,
  PURCHASE_RECEIVE_INVOICE_RETURN: `${FIREBASE_URL_PREFIX}Purchase\\Receive\\InvoiceReturn/`,
  INVENTORY_STOCK_LOSS_GAIN: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\LossGain/`,
  FARM_FG_RECEIVE: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\Receive/`,
  SAMPLE_INVOICE: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\SampleInvoice/`,
  PRODUCTION_PLANNING_RECEIVE: `${FIREBASE_URL_PREFIX}Manufacture\\ProductionPlanning\\Receive/`,
  INVENTORY_STOCK_TRANSFER_RECEIVE: `${FIREBASE_URL_PREFIX}Inventory\\Transaction\\Stock\\Transfer\\Receive`,
  SALE_ORDER: `${FIREBASE_URL_PREFIX}Sale\\Order/`,
  SALE_INVOICE: `${FIREBASE_URL_PREFIX}Sale\\Invoice/`,
  SALE_RETURN: `${FIREBASE_URL_PREFIX}Sale\\SaleReturn/`,
};
const FETCH_TYPES = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
};
const FETCH_API_END = {
  FIND: "FIND",
  SEARCH: "SEARCH",
};

export const PAGINATION_LIMIT = 8;

export const RouteConstants = {
  accounting: [
    ["accounting/account", "ACCOUNTING.ACCOUNT.ACCOUNT", ACCOUNTING],
    ["accounting/cost", "ACCOUNTING.COST.COST", ACCOUNTING],
    ["accounting/profit", "ACCOUNTING.PROFIT.PROFIT", ACCOUNTING],
    ["accounting/ledger", "ACCOUNTING.ACCOUNT.ACCOUNT", ACCOUNTING],
    ["accounting/bank", "ACCOUNTING.BANK.BANK", ACCOUNTING],
    ["accounting/bank-account", "ACCOUNTING.BANK.BANK", ACCOUNTING],
    ["accounting/year", "ACCOUNTING.YEAR.YEAR", ACCOUNTING],
    [
      "accounting/customer-advance",
      "ACCOUNTING.CUSTOMER_ADVANCE.CUSTOMER_ADVANCE",
      ACCOUNTING,
    ],
    [
      "accounting/supplier-advance",
      "ACCOUNTING.SUPPLIER_ADVANCE.SUPPLIER_ADVANCE",
      ACCOUNTING,
    ],
    [
      "accounting/supplier-advance-adjustment",
      "ACCOUNTING.SUPPLIER_ADVANCE_ADJUSTMENT.SUPPLIER_ADVANCE_ADJUSTMENT",
      ACCOUNTING,
    ],
    [
      "accounting/transaction",
      "ACCOUNTING.TRANSACTION.TRANSACTION",
      ACCOUNTING,
    ],
    [
      "accounting/purchase-payment",
      "ACCOUNTING.SUPPLIER_PAYMENT.SUPPLIER_PAYMENT",
      ACCOUNTING,
    ],
    [
      "accounting/expense-budget",
      "ACCOUNTING.EXPENSE_BUDGET.EXPENSE_BUDGET",
      ACCOUNTING,
    ],
  ],
  sale: [
    ["sale/discount", "SALE.DISCOUNT.DISCOUNT", SALE],
    ["sale/van", "SALE.VAN.VAN", SALE],
    ["sale/order", "SALE.ORDER.ORDER", SALE],
    ["sale/route", "SALE.ROUTE.ROUTE", SALE],
    ["sale/route-plan", "SALE.PLAN.PLAN", SALE],
    ["sale/price", "SALE.PRICE.PRICE", SALE],
    ["sale/wish-list", "SALE.ORDER.ORDER", SALE],
    ["sale/sale-foc", "SALE.SALE_FOC.SALE_FOC", SALE],
    ["sale/sales-target", "SALE.SALE_TARGET.SALE_TARGET", SALE],
    [
      "sale/market-organogram",
      "SALE.MARKET_ORGANOGRAM.MARKET_ORGANOGRAM",
      SALE,
    ],
    [
      "sale/delivery-assistant",
      "SALE.DELIVERY_ASSISTANT.DELIVERY_ASSISTANT",
      SALE,
    ],
    ["sale/collection", "SALE.COLLECTION.COLLECTION", SALE],
    ["sale/deposit", "SALE.COLLECTION_DEPOSIT.COLLECTION_DEPOSIT", SALE],
    [
      "sale/sale-collection-discount",
      "SALE.SALE_COLLECTION_DISCOUNT.SALE_COLLECTION_DISCOUNT",
      SALE,
    ],
    ["sale/invoice", "SALE.INVOICE.INVOICE", SALE],
    ["sale/sample-invoice", "SALE.SAMPLE_INVOICE.SAMPLE_INVOICE", SALE],
    ["sale/sale-return", "SALE.SALE_RETURN.SALE_RETURN", SALE],
  ],
};

const DATE_FORMAT = "DD-MM-YYYY";
const TIME_FORMAT = "HH:mm";
const setTimeFormat = (time) => time.format("HH:mm:ss");
const getTimeFormat = (time) => moment(new Date(time), TIME_FORMAT);
const viewTimeFormat = (time) => moment(time).format(TIME_FORMAT);
export const viewDateFormatTime = (date) => {
  if (date) {
    if (Array.isArray(date)) {
      return moment(date?.[0])?.format('DD MM YYYY hh:mm:ss')
    }
    return moment(date)?.format('DD MM YYYY hh:mm:ss')
  }
  return ''
};
const reportServerDate = (days = 1) =>
  process.env.REACT_APP_ENV === "production" &&
  moment().subtract(days, "days").format("YYYY-MM-DD");
const reportDateTime = () => moment().format(`${DATE_FORMAT} HH:mm:ss A`);

const setDateFormat = (date) => date.format(DATE_FORMAT);
const getDateFormat = (date) => moment(new Date(date), DATE_FORMAT);
const viewDateFormat = (date) => {
  if (date) {
    if (Array.isArray(date)) {
      return moment(date?.[0])?.format(DATE_FORMAT);
    }
    return moment(date)?.format(DATE_FORMAT);
  }
  return "";
};

const codeMessage = {
  200: "The server successfully returned the requested data.  ",
  201: "New or modified data is successful.",
  202: "A request has entered the background queue (asynchronous task).",
  204: "The data was deleted successfully.",
  400: "The request was made with an error and the server did not perform any new or modified data operations.",
  401: "User does not have permission (token, username, password is incorrect).",
  403: "The user is authorized, but access is forbidden.",
  404: "The request is made for a record that does not exist and the server does not operate.",
  405: "Method Not Allowed.",
  406: "The format of the request is not available.",
  410: "The requested resource is permanently deleted and will not be retrieved.",
  422: "A validation error occurred when creating an object.",
  500: "An error occurred on the server. Please check the server.",
  502: "Gateway error.",
  503: "The service is unavailable and the server is temporarily overloaded or maintained.",
  504: "The gateway timed out.",
};

export {
  codeMessage, CONST_DATA_KEY,
  DATE_FORMAT,
  FETCH_API_END,
  FETCH_TYPES,
  FIREBASE_KEY, getDateFormat,
  getTimeFormat, PAGINATION_TYPES,
  PATCH_PAGINATION_TYPES, reportDateTime,
  reportServerDate,
  setDateFormat,
  setTimeFormat, STORAGE_KEY,
  TIME_FORMAT, viewDateFormat,
  viewTimeFormat
};

